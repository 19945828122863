import React, { Component } from 'react';

export class HeaderProgramsPage extends Component {
  state = {
    className: 0,
    zbir: 0,
    total: 0
  };

  handleSlider = () => {
    let zbir = this.state.zbir;
    let total = this.state.total;
    if (this.state.total > 0) {
      //   total = total + 1;
      zbir = 0;
      total = 0;
      this.setState({ zbir, total });
    } else {
      zbir = 0;
      total = 1;
      this.setState({ zbir, total });
    }
  };
  render() {
    return (
      <header className="header-programs">
        <div className={`header-programs__box `}>
          <div className="header-programs--text2">
            {/* KICK BOX * VEŽBE OBLIKOVANJA * PILATES * AEROBIK */}
            {/* TERETANA & FITNESS * INTEGRAL * TERETANA & FITNESS * INTEGRAL */}
            {/* <span className="header2"> */}
            {/* <div className="animation2"></div> */}
          </div>
          <div className="header-programs--text1">
            KICK BOX * VEŽBE OBLIKOVANJA * PILATES * AEROBIK * CARDIO & BODY MIX
          </div>
          {/* <h1>Vežbe Oblikovanja</h1> */}
          {/* <span className="animation2__header2"></span> <br />
              TERETANA & FITNESS
              <br /> */}
          <div className={`cover-programs cover-programs--${this.state.total}`}>
            {/* <div className="animation-div">
              <div className="animation1"></div>
            </div> */}

            {/* <div class="header3__anim rocket1">
                <i class="fas fa-dumbbell"> </i>
                🏋️‍♀️
              </div> */}
            {/* <button onClick={this.handleSlider} className="header__button">
                    <i class="fas fa-dumbbell"> </i>
                    🏋️‍♀️
            </button> */}
          </div>
          {/* <div className="header-programs__title">
            KICK BOX * VEŽBE OBLIKOVANJA * PILATES * AEROBIK * JOGA
          </div>
          <div className="header-programs__title1">
            KICK BOX * VEŽBE OBLIKOVANJA * PILATES * AEROBIK * JOGA
          </div> */}
        </div>
      </header>
    );
  }
}

export default HeaderProgramsPage;

import React, { Component } from 'react';
import HeaderProgramsPage from '../../components/Header/HeaderProgramsPage';
import Modal from '../../components/Modal/Modal';
import { LayoutMain } from '../LayoutContainer/LayoutMain';
import img1 from '../../img1/getinshape.jpg';
import img2 from '../../img1/vezbeObl.jpg';
import img3 from '../../img1/pilates-novo.jpg';
import img4 from '../../img1/kickbox-novo.jpg';
import img5 from '../../img1/kickbox/novo.jpg';
import img6 from '../../img1/gym-novo.jpg';
export class ProgramsPage extends Component {
  state = { openModal: true, openModal1: '' };
  componentDidMount() {
    window.scrollTo(0, 0);
    const modalFromStorage = JSON.parse(localStorage.getItem('modalPrograms'));
    if (modalFromStorage !== undefined) {
      this.setState({ openModal1: modalFromStorage });
    }
  }
  click() {
    localStorage.setItem('modalPrograms', false);
    this.setState({ openModal1: false });
  }
  render() {
    return (
      <LayoutMain>
        <HeaderProgramsPage></HeaderProgramsPage>
        {this.state.openModal && this.state.openModal1 !== false ? (
          <Modal
            styleModal="modal"
            styleModalBody="modal__body "
            click={() => this.click()}
          >
            <div className="modal__div">
              <div className="modal__home-page">
                <div className="modal__home-page--title0">
                  U toku formiranje grupa, prijave na broj <br /> 011/311-32-25
                  i 060/165-75-72
                </div>
                <h1 className="modal__home-page--title"> NOVO!</h1>
                <br />
                <h1 className="modal__home-page--title1"> Pilates </h1>
                <br />
                <br />
                <h1 className="modal__home-page--title2"> Cardio & body mix</h1>
                <h3 className="modal__home-page--title3">
                  Jutarnji i večernji termini!
                </h3>
              </div>

              <button
                onClick={() => {
                  localStorage.setItem('modalHome', false);
                  this.setState({ openModal: false });
                }}
                className="button btn--animated btn--pink-dark btn"
              >
                ⇦ Vrati se na stranu
              </button>
            </div>
          </Modal>
        ) : null}
        <div className="programs-container">
          <span className="gym">
            <span className="gym__icons gym__icons--2"></span>
            <span className="gym__icons gym__icons--3"></span>
            <span className="gym__icons gym__icons--1"></span>
            <span className="header-about__title1">🥊 </span>

            <span className="gym__icons gym__icons--2"></span>
            <span className="gym__icons gym__icons--3"></span>
            <span className="gym__icons gym__icons--1"></span>
            <span className="header-about__title1">🥊 </span>

            {/* <span className="gym__icons gym__icons--2"></span>
            <span className="gym__icons gym__icons--3"></span>
            <span className="gym__icons gym__icons--1"></span>
            <span className="header-about__title1">🥊 </span> */}
          </span>
          <div className="img-box">
            <div className="img-div">
              <img src={img1} className="img" alt="" />
            </div>
            <div className="img-div">
              <img src={img2} className="img" alt="" />
            </div>
            <div className="img-div">
              <img src={img3} className="img" alt="" />
            </div>
            <div className="img-div">
              <img src={img4} className="img" alt="" />
            </div>
            <div className="img-div">
              <img src={img5} className="img" alt="" />
            </div>
            <div className="img-div">
              <img src={img6} className="img" alt="" />
            </div>
          </div>
          <h1 className="program-section__title">Programi</h1>
          <div className="programs">
            <div className="programs__box programs__box--reverse">
              <div className="programs__text programs__text--1">
                {' '}
                <h1 className="programs__text--title">Kick box</h1>
              </div>
              <div className="programs__about">
                Dinamičan, kardio-intenzivan trening, uz pomoć kog uz
                savladavanje veštine, radite i na izdržljivosti, snazi,
                koordinaciji i koncentraciji. <br />
                Oprema: rukavice i peškir <br />
                Termini: <br />
                Utorak i četvrtak: 20h - 21h
                <br />
                Subota: 12h - 13h
              </div>
            </div>
            <div className="programs__box ">
              <div className="programs__text programs__text--2">
                {' '}
                <h1 className="programs__text--title">Vežbe oblikovanja</h1>
              </div>
              <div className="programs__about">
                Raznovrsan i dinamičan trening, srednjeg intenziteta,
                predstavlja spoj dinamičnih vežbi, vežbi jačanja i vežbi
                istezanja koje za cilj imaju poboljšavanje i jačanje celokupne
                muskulature i oblikovanje tela. <br />
                Termini:
                <br /> Ponedeljak 18:30h - 19:30h; <br />
                Utorak i četvrtak 18h - 19h; Subota 11h - 12h
              </div>
            </div>
            <div className="programs__box programs__box--reverse">
              <div className="programs__text programs__text--3">
                <h1 className="programs__text--title">Pilates</h1>
              </div>
              <div className="programs__about">
                Forma vežbanja koja razvija specifičnu mišićnu snagu i
                fleksibilnost mišića i zglobova, čime se postiže optimalna
                telesna uravnoteženost. Pilates izdužuje i tonizira telo, utiče
                na elegantnu posturu i oslobađa stresa.
                <br /> U toku formiranje grupa, prijave na broj 011/311-32-25 i
                060/165-75-72
                {/* <br />
                Oprema: peškir */}
              </div>
            </div>
            <div className="programs__box">
              {/* <h1 className="">Aerobik</h1> */}

              <div className="programs__text programs__text--4">
                <h1 className="programs__text--title">Aerobik</h1>
              </div>
              <div className="programs__about">
                Vrsta treninga koja kombinuje ritmičke aerobne vežbe sa
                istezanjem i vežbama snage. Cilj aerobika je povećati snagu,
                elastičnost i kardiovaskularni kapacitet organizma.
                <br />
                Oprema: peškir <br />
                Termini: Ponedeljak, sreda i petak 20h - 21h
              </div>
            </div>
            <div className="programs__box programs__box--reverse">
              <div className="programs__text programs__text--5">
                <h1 className="programs__text--title">Joga</h1>
              </div>
              <div className="programs__about">
                Drevna metoda vežbanja koje povezuje telo, um i duh kroz
                različite položaje tela, meditaciju i kontrolisano disanje.
                Pospešuje pokretljivost zglobova, kardiovaskularno zdravlje,
                pravilno disanje i kontrolu pokreta.
                <br />
              </div>
            </div>
            <div className="programs__box programs__box--reverse">
              <div className="programs__text programs__text--6">
                <h1 className="programs__text--title">Cardio & body mix</h1>
              </div>
              <div className="programs__about">
                Dinamičan, kardio-intenzivan trening, predstavlja spoj
                dinamičnih vežbi i vežbi jačanja koje za cilj imaju
                poboljšavanje i jačanje celokupne muskulature i oblikovanje
                tela.
                <br /> U toku formiranje grupa, prijave na broj 011/311-32-25 i
                060/165-75-72
                <br />
              </div>
            </div>
          </div>
        </div>
      </LayoutMain>
    );
  }
}

export default ProgramsPage;

import React, { Component } from 'react';

import { LayoutMain } from '../../containers/LayoutContainer/LayoutMain';
export class PricePage extends Component {
  state = {
    indCard: false,
    programsCard: false,
    gymCard: false,
    terAndProgramsCard: false,
    card: [
      {
        name: 'Vežbe oblikovanja',
        cat: [
          { kartica: '12termina', cena: '2500' },
          { kartica: '8termina', cena: '2200' }
        ],
        style: 'u-text-1row-smaller'
      },
      {
        name: 'Aerobik',
        cat: [{ kartica: '12termina', cena: '2500' }],
        style: 'u-text-1row'
      },
      {
        name: 'Kick box',
        cat: [{ kartica: '12termina', cena: '3000' }],
        style: 'u-text-1row'
      },

      {
        name: 'Pilates',
        cat: [{ kartica: '12termina', cena: '2800' }],
        style: 'u-text-1row'
      },
      {
        name: 'Cardio & body mix',
        cat: [{ kartica: '12termina', cena: '2800' }],
        style: 'u-text-1row'
      }
    ],
    cardTer: [
      {
        name: 'Teretana',
        cat: [
          { kartica: 'Mesečna', cena: '2700' },
          { kartica: '16termina', cena: '2500' },
          { kartica: '12termina', cena: '2200' },
          { kartica: '8termina', cena: '1800' },
          { kartica: 'Dnevna', cena: '400' }
        ],
        style: 'u-text-1row'
      }
    ],
    cardTerAndPrograms: [
      {
        name: 'Vežbe oblikovanja - 12termina',
        cat: [
          { kartica: '+ Teretana Mesečna', cena: 1100 },
          { kartica: '+ Teretana 16termina', cena: 1000 },
          { kartica: '+ Teretana 12termina', cena: 900 },
          { kartica: '+ Teretana  8termina', cena: 800 }
        ],
        cena: 2500,
        style: 'u-text-2row'
      },
      {
        name: 'Vežbe oblikovanja - 8termina',
        cat: [
          { kartica: '+ Teretana Mesečna', cena: 1100 },
          { kartica: '+ Teretana 16termina', cena: 1000 },
          { kartica: '+ Teretana 12termina', cena: 900 },
          { kartica: '+ Teretana  8termina', cena: 800 }
        ],
        cena: 2200,
        style: 'u-text-2row'
      },
      {
        name: 'Aerobik - 12termina',
        cat: [
          { kartica: '+ Teretana Mesečna', cena: 1100 },
          { kartica: '+ Teretana 16termina', cena: 1000 },
          { kartica: '+ Teretana 12termina', cena: 900 },
          { kartica: '+ Teretana  8termina', cena: 800 }
        ],
        cena: 2500,
        style: 'u-text-2row'
      },
      {
        name: 'Pilates - 12termina',
        cat: [
          { kartica: '+ Teretana Mesečna', cena: 1100 },
          { kartica: '+ Teretana 16termina', cena: 1000 },
          { kartica: '+ Teretana 12termina', cena: 900 },
          { kartica: '+ Teretana  8termina', cena: 800 }
        ],
        cena: 2800,
        style: 'u-text-2row'
      },
      {
        name: 'Cardio & body mix - 12termina',
        cat: [
          { kartica: '+ Teretana Mesečna', cena: 1100 },
          { kartica: '+ Teretana 16Termina', cena: 1000 },
          { kartica: '+ Teretana 12termina', cena: 900 },
          { kartica: '+ Teretana  8Termina', cena: 800 }
        ],
        cena: 2800,
        style: 'u-text-2row'
      },

      {
        name: 'Kick box - 12Termina',
        cat: [
          { kartica: '+ Teretana Mesečna', cena: 1300 },
          { kartica: '+ Teretana 16Termina', cena: 1200 },
          { kartica: '+ Teretana 12Termina', cena: 1100 },
          { kartica: '+ Teretana  8termina', cena: 900 }
        ],
        cena: 3000,
        style: 'u-text-2row'
      }
    ],
    cardInd: [
      {
        name: '2 vežbača',
        cat: [
          { kartica: '12termina', cena: '12000' },
          { kartica: '8termina', cena: '10500' }
        ],
        style: 'u-text-2row'
      },
      {
        name: 'do 4 vežbača',
        cat: [
          { kartica: '12termina', cena: '7000' },
          { kartica: '8termina', cena: '5500' }
        ],
        style: 'u-text-2row'
      },
      {
        name: 'do 8 vežbača',
        cat: [
          { kartica: '12termina', cena: '3500' },
          { kartica: '8termina', cena: '3200' }
        ],
        style: 'u-text-2row'
      }
    ],
    firstCard: [
      {
        name: 'Teretana',
        cards: [
          { name: 'Teretana', id: 'gymCard' },
          {
            name: 'Teretana + Grupni program',
            id: 'terAndProgramsCard'
          },
          { name: 'Teretana * Vođeni treninzi', id: 'indCard' }
        ]
      },
      {
        name: 'Grupni programi',
        cards: [
          { name: 'Grupni programi', id: 'programsCard' },
          { name: 'Grupni program + teretana', id: 'terAndProgramsCard' }
        ]
      },
      {
        name: 'Vođeni treninzi',
        cards: [{ name: 'Vođeni treninzi', id: 'indCard' }]
      }
    ]
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  handleScrollTo = (ref) => {
    if (ref /* + other conditions */) {
      ref.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  handleGymPrices = (name) => {
    this.handleScrollTo();
    let gymCard = this.state.gymCard;
    let indCard = this.state.gymCard;
    let terAndProgramsCard = this.state.gymCard;
    let programsCard = this.state.programsCard;
    gymCard = false;
    indCard = false;
    terAndProgramsCard = false;
    programsCard = false;
    if (name === 'gymCard') {
      gymCard = true;
    }
    if (name === 'programsCard') {
      programsCard = true;
    }
    if (name === 'indCard') {
      indCard = true;
    }
    if (name === 'terAndProgramsCard') {
      terAndProgramsCard = true;
    }
    this.setState({
      gymCard,
      indCard,
      terAndProgramsCard,
      programsCard
    });
  };

  render() {
    const indCard = this.state.indCard;
    const terAndProgramsCard = this.state.terAndProgramsCard;
    const programsCard = this.state.programsCard;
    const gymCard = this.state.gymCard;
    return (
      <React.Fragment>
        <LayoutMain>
          {/* <HeaderProgramsPage></HeaderProgramsPage> */}

          <div className="price-section">
            <div className="price-section__title">Cenovnik</div>
            <div className="price-section__box">
              {this.state.firstCard.map((card) => (
                <div key={card.name} className="card-side">
                  <div className="card-side__side card-side__side--front">
                    <h1 className="u-text-center card-side__title">
                      {card.name}
                    </h1>
                    <h1 className="u-flex-text-center card-side__title--0">
                      ✥
                    </h1>
                  </div>
                  <div className="card-side__side card-side__side--back">
                    {card.cards.map((c) => (
                      <li key={c.name} className="u-li-style">
                        {c.name}
                        <br />
                        <button
                          className="button btn"
                          onClick={() => this.handleGymPrices(c.id)}
                        >
                          Vidi Cene
                        </button>
                      </li>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            {/* {cards.map(card => card.name)} */}
            {programsCard ? (
              <div
                ref={this.handleScrollTo}
                className="price-section__programs"
              >
                {this.state.card.map((m) => (
                  <div key={m.name} className={`price-card ${m.style}`}>
                    <div className="price-card__top">
                      <div className="price-card__top--title">{m.name}</div>
                    </div>

                    <div className={`price-card__main`}>
                      {m.cat.map((b) => (
                        <li className="price-card__li">
                          <span className="price-card__li--left">
                            {b.kartica}
                          </span>
                          <span className="price-card__li--right">
                            {' '}
                            {b.cena}
                          </span>
                        </li>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
            {gymCard ? (
              <div ref={this.handleScrollTo} className="price-section__gym">
                {this.state.cardTer.map((m) => (
                  <div className={`price-card ${m.style}`}>
                    <div className="price-card__top">
                      <div className="price-card__top--title">{m.name}</div>
                    </div>

                    <div className={`price-card__main`}>
                      {m.cat.map((b) => (
                        <li key={b.kartica} className="price-card__li">
                          <span className="price-card__li--left">
                            {b.kartica}
                          </span>
                          <span className="price-card__li--right">
                            {' '}
                            {b.cena}
                          </span>
                        </li>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
            {terAndProgramsCard ? (
              <div ref={this.handleScrollTo} className="price-section__gym">
                {this.state.cardTerAndPrograms.map((m) => (
                  <div key={m.name} className={`price-card ${m.style}`}>
                    <div className="price-card__top">
                      <div className="price-card__top--title">{m.name}</div>
                    </div>

                    <div className={`price-card__main`}>
                      {m.cat.map((b) => (
                        <li key={b.kartica} className="price-card__li">
                          <span className="price-card__li--left">
                            {b.kartica}
                          </span>
                          <span className="price-card__li--right">
                            {' '}
                            {b.cena + m.cena}
                          </span>
                        </li>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
            {indCard ? (
              <div ref={this.handleScrollTo} className="price-section__gym">
                {this.state.cardInd.map((m) => (
                  <div key={m.name} className={`price-card ${m.style}`}>
                    <div className="price-card__top">
                      <div className="price-card__top--title">{m.name}</div>
                    </div>

                    <div className={`price-card__main`}>
                      {m.cat.map((b) => (
                        <li key={b.kartica} className="price-card__li">
                          <span className="price-card__li--left">
                            {b.kartica}
                          </span>
                          <span className="price-card__li--right">
                            {' '}
                            {b.cena}
                          </span>
                        </li>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ) : null}

            {/* {this.state.cardTer.map(m => (
            <div className={`price-card ${m.style}`}>
              <div className="price-card__top">
                <div className="price-card__top--title">{m.name}</div>
              </div>

              <div className={`price-card__main`}>
                {m.cat.map(b => (
                  <li className="price-card__li">
                    <span className="price-card__li--left">{b.kartica}</span>
                    <span className="price-card__li--right"> {b.cena}</span>
                  </li>
                ))}
              </div>
            </div>
          ))} */}
          </div>
        </LayoutMain>
      </React.Fragment>
    );
  }
}

export default PricePage;

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import HomePage from './containers/HomePage/HomePage';

import { ProgramsPage } from './containers/Programs/ProgramsPage';

import { PricePage } from './components/PricePage/PricePage';
import { PhotosPage } from './components/PhotosPage/PhotosPage';
import { AboutUsPage } from './components/AboutUs/AboutUsPage';
import { ContactPage } from './containers/ContactForm/ContactPage';

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/teretana/integral" component={AboutUsPage} />
        <Route path="/cenovnik" component={PricePage} />
        <Route path="/galerija" component={PhotosPage} />
        <Route path="/kontakt" component={ContactPage} />

        <Route path="/programi" component={ProgramsPage} />

        <Route path="/" component={HomePage} />
      </Switch>
    </div>
  );
}

export default App;

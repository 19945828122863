import React, { Component } from 'react';

import { LayoutMain } from '../../containers/LayoutContainer/LayoutMain';
import { HeaderAboutUs } from '../Header/HeaderAboutUs';
export class AboutUsPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <React.Fragment>
        <HeaderAboutUs></HeaderAboutUs>

        <LayoutMain>
          {/* <HeaderProgramsPage></HeaderProgramsPage> */}
          <div className="about">
            {/* <div className="about__header"></div> */}
            <div className="about__box">
              <div className="about__box--text1">
                <h1 className="u-text-center">Integral priča</h1>
                1993. godine otvorili smo prvu teretanu, koja je ujedno bila
                među prvim teretanama u Beogradu. Integral je i među prvim
                teretanama koja je u svojim prostorijama u sklopu teretane
                organizovala i osnovala Klub borilačkih sportova i fizičku
                pripremu različitih kategorija vežbača. Ciljevi Kluba bili su
                razvoj borilačkih veština – karate, boks i kik boks, kao i
                fizička priprema takmičara, sa jedne strane,{' '}
                {/* za treniranje istih */} a sa druge popularizacija grupnih
                programa za rekreativce – vežbe oblikovanja, aerobik i pilates.
                {/* objedinili */}
                {/* Tokom godina radili smo na više lokacija. Organizovali smo vežbe u
              skoro svim delovima grada. Za to vreme menjali smo i adaptirali
              prostore tražeći optimalne uslove, ali ono što nismo menjali jeste
              ideja sa kojom smo i počeli - pronaći mesto i kreirati prostor u
              kome će se svako osećati prijatno, u kome će vladati sportski duh
              i u kom će se negovati vežbački ambijent. Na trenutnoj lokaciji
              smo više od deset godina. */}
                {/* Motiv nismo promenili - konstantan razvoj
                uz negovanje proverenih vrednosti, u profesiji koja nema
                granice. */}
                {/* Prostor u kome je teretana odlikuje se prostranošću i odličnom 
              opremljenošću. Pored prostora u kome je teretana, postoji i velika
              sala za grupne programe površine 120 m², kao i dve manje sale za
              grupne programe (u pripremi). U salama organizujemo različite
              grupne programe, koje treneri osmišljavaju i sprovode posvećeno i
              profesionalno. Rad u Klubu odvija se pod nadzorom stručnog tima
              trenera. */}
              </div>

              <div className="about__box--text2">
                <h1 className="about__header1  u-text-center">O Klubu</h1>
                Inicijator/osnivač Kluba, kao i pokretač svih dešavanja je
                Dragan Veselinović - internacionalni instruktor borilačkih
                veština. U Klubu borilačkih veština organizovane su grupe
                različitih uzrasta za karate, boks i kik-boks (rekreativni i
                takmičarski), a u sklopu Kluba  posebno se radila i fizička
                priprema takmičara. Klub je bio član mnogih organizacija i
                asocijacija unutar zemlje, kao i internacionalnih. Organizovali 
                smo više seminara  na domaćem i internacionalnom  nivou, čiji su
                učesnici bili neki od najvećih imena u svetu borilačkih veština.
                Članovi Kluba  učestvovali  su na domaćim i internacionalnim
                turnirima, na kojim su osvajali titule. Nakon godina rada Klub
                je zbog premeštanja u drugi prostor u jednom periodu napustio
                takmičarski deo rada, ali nastavio sa grupama za rekreativce.
              </div>

              <div className="about__box--text3">
                <h1 className="about__header1  u-text-center">Cilj Kluba</h1>{' '}
                Cilj Kluba bio je popularizacija, širenje ovog vida vežbanja i
                stvaranje što boljih uslova kako za rekreativce tako i
                takmičare. Rad se i dalje odvija u sklopu teretane Integral, gde
                je aktivna grupa za rekreativce i sve ljubitelje ove veštine,
                kao i individualni treninzi. S obzirom da je Klub okupljao
                saradnike i stručnjake iz oblasti borilačkih veština, i da je
                dobru saradnju zadržao sa međunarodnim asocijacijama, višim
                stručnim organizacijama i stručnjacima iz oblasti fizičke
                pripreme, koji bi učestvovali u pripremama sportista, u skladu
                sa stvaranjem uslova, Klubu je u planu da se vrati u aktivno
                funkcionisanje i, što se tiče takmičarskog dela, da (re)oformi
                grupe različitih uzrasta za rekreativce i takmičare.
              </div>
            </div>
          </div>
        </LayoutMain>
      </React.Fragment>
    );
  }
}

export default AboutUsPage;

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../../components/Header/Header';
import LayoutMain from '../LayoutContainer/LayoutMain';
import Modal from '../../components/Modal/Modal';

class HomePage extends Component {
  state = {
    openModal: true,
    cards: [
      {
        name: 'Vežbe oblikovanja',
        id: 5,
        link: '/programi',
        per: { re: { e: '' } }
      },
      {
        name: 'Aerobik',
        id: 6,
        link: '/programi'
      },

      {
        name: 'Pilates',
        id: 7,
        link: '/programi'
      },

      {
        name: 'Joga',
        id: 8,
        link: '/programi'
      },
      {
        name: 'Kik boks',
        id: 9,
        link: '/programi'
      },
      {
        name: 'Cardio & body mix',
        id: 10,
        link: '/programi'
      }
    ],
    cards1: [
      { name: 'Teretana', id: 1, link: '#' },

      { name: 'Individualni treninzi', id: 2, link: '/programi' },
      {
        name: 'Vođeni treninzi (2 vežbača)',
        id: 3,
        link: '/programi'
      },
      {
        name: 'Vođeni treninzi (manje grupe)',
        id: 4,
        link: '/programi'
      }
    ]
  };
  componentDidMount() {
    localStorage.setItem('modalHome', true);
  }
  handleAboutUsBtn = () => {
    this.props.history.push('/teretana/integral');
  };
  click() {
    localStorage.setItem('modalHome', false);
    this.setState({ openModal: false });
  }
  render() {
    return (
      <React.Fragment>
        <Header></Header>
        {this.state.openModal &&
        JSON.parse(localStorage.getItem('modalHome')) !== false ? (
          <Modal
            styleModal="modal"
            styleModalBody="modal__body "
            click={() => this.click()}
          >
            <div className="modal__div">
              <div className="modal__home-page">
                <div className="modal__home-page--title0">
                  U toku formiranje grupa, prijave na broj <br /> 011/311-32-25
                  i 060/165-75-72
                </div>
                <h1 className="modal__home-page--title">NOVO!</h1>
                <br />
                <h1 className="modal__home-page--title1"> Pilates </h1>
                <br />
                <br />
                <h1 className="modal__home-page--title2"> Cardio & body mix</h1>
                <h3 className="modal__home-page--title3">
                  Jutarnji i večernji termini!
                </h3>
              </div>

              <button
                onClick={() => {
                  localStorage.setItem('modalHome', false);
                  this.setState({ openModal: false });
                }}
                className="button btn--animated btn--pink-dark btn"
              >
                ⇦ Vrati se na stranu
              </button>
            </div>
          </Modal>
        ) : null}
        <LayoutMain>
          <div className="about-section">
            <div className="about-section__top">
              <div className="about-section__top--2">
                <h1 className="about-section__top-photo header1"> </h1>{' '}
              </div>
              <div className="about-section__top--1">
                {' '}
                <span className="about-section__top--35"></span>{' '}
                <span className="about-section__top--9"></span>{' '}
                <span className="about-section__top--10">
                  <span className="about-section__top--36"></span>
                </span>{' '}
                <span className="about-section__top--11">
                  {' '}
                  <span className="about-section__top--36"></span>{' '}
                </span>{' '}
                <span className="about-section__top--12">
                  {' '}
                  <span className="about-section__top--36"></span>{' '}
                </span>{' '}
                <span className="about-section__top--13">
                  <span className="about-section__top--36"></span>{' '}
                </span>{' '}
              </div>
              <div className="about-section__top--3">
                <span className="about-section__top--34"></span>{' '}
                <span className="about-section__top--4"></span>{' '}
                <span className="about-section__top--5">
                  <span className="about-section__top--33"></span>
                </span>{' '}
                <span className="about-section__top--6">
                  {' '}
                  <span className="about-section__top--33"></span>{' '}
                </span>{' '}
                <span className="about-section__top--7">
                  {' '}
                  <span className="about-section__top--33"></span>{' '}
                </span>{' '}
                <span className="about-section__top--8">
                  <span className="about-section__top--33"></span>{' '}
                </span>{' '}
              </div>
            </div>
          </div>
          <div className="about-section__text">
            <div className="about-section__text--1">
              Prostor u kom se nalazi teretana odlikuje velika prostranost i
              veliki broj sprava, klasičnog i modernog dizajna i
              funkcionalnosti. U okviru teretane, a na raspolaganju vežbačima,
              nalaze se i različiti rekviziti -{' '}
              <span style={{ fontStyle: 'italic' }}>kettlebell</span>, tegići,
              vijače, gume, lopte... U istom prostoru nalazi se i više ripstola,
              kao i deo slobodan za različite vežbe i aktivnosti. Pored ovog
              dela, nalazi se i prostorija sa džakom i još prostora za različite
              vežbe, za zagrevanje i istezanje. Grupni programi se odvijaju u
              odvojenoj velikoj sali sa podlogom od strunjača i velikim brojem
              džakova, i još dve sale namenjene i prilagođene za grupne
              programe. <br />
              Rad u Klubu odvija se pod nadzorom stručnog tima.
            </div>
            <div className="about-section__text--3"></div>
            <div className="about-section__text--2">
              Sportsko-rekreativni centar i Klub borilačkih sportova "Integral"
              postoji više od dvadeset godina. Tokom godina radili smo na više
              lokacija. Organizovali smo vežbe u skoro svim delovima grada,
              adaptirali prostore tražeći optimalne uslove, ali ono što svih
              ovih godina nismo menjali jeste ideja sa kojom smo i počeli -
              kreirati i prilagoditi prostor za različite vidove aktivnosti u
              kome će se svako osećati prijatno, u kome će vladati sportski duh
              i u kom će se negovati vežbački ambijent. Teretana Integral, u
              Bloku 24, postoji vec dugi niz godina.
              <button
                className="about-section__button"
                onClick={() => this.handleAboutUsBtn()}
              >
                Integral...
                <div
                  className="about-section__button1"
                  onClick={() => this.handleAboutUsBtn()}
                >
                  ➩
                </div>
              </button>
            </div>
          </div>

          <div className="programs-section">
            <div className="programs-section__text">
              {' '}
              <div
                style={{
                  background: '#4d4d4d',
                  padding: '2rem',
                  boxShadow: '0 0 8px #4d4d4d'
                }}
              >
                <h2 className="programs-section__title header3 u-text-center">
                  Programi
                </h2>
                Teretana Integral u svojoj ponudi pored teretane sadrži
                raznovrsan izbor grupnih programa. Od borilačkih veština, preko
                aerobnih i dinamičnih programa, programa srednjeg intenziteta
                namenjenih oblikovanju tela do treninga namenjenih jačanju i
                istezanju mišića, pokretljivosti zglobova, pravilnom disanju i
                kontroli pokreta.
              </div>
              <div className="flex">
                <ul className="list">
                  {this.state.cards1.map((card) => (
                    <li
                      key={card.id}
                      className={`list__item list__item--${card.id}`}
                    >
                      <NavLink className="list__link" to={card.link}>
                        {card.name}
                      </NavLink>
                    </li>
                  ))}
                </ul>
                <ul className="list">
                  {this.state.cards.map((card) => (
                    <li
                      key={card.id}
                      className={`list__item list__item--${card.id}`}
                    >
                      <NavLink className="list__link" to={card.link}>
                        {card.name}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </LayoutMain>
      </React.Fragment>
    );
  }
}

export default HomePage;

import React, { Component } from 'react';
import axios from 'axios';
import { LayoutMain } from '../LayoutContainer/LayoutMain';
import Modal from '../../components/Modal/Modal';

export class ContactPage extends Component {
  state = {
    fname: '',
    lname: '',
    email: '',
    message: '',
    mailSent: false,
    error: null,
    sendMsg: false
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleSubmit = e => {
    e.preventDefault();

    // const fullName = this.state.fname
    // const email = this.state.email
    // const userText = this.state.message

    axios
      .post('https://teretanaintegral.appspot.com/api/v1/users', {
        email: this.state.email,
        fullName: this.state.fname,
        message: this.state.message
      })
      .then(res => {
        // todo: dodaj message
        // console.log(res);
      })
      .catch(err =>
        // todo: dodaj error handling
        console.log(err)
      );

    // const data = `fullName=${fullName}&userText=${userText}&email=${email}`
    // axios({
    //   method: 'POST',
    //   url: `https://www.teretanaintegral.rs/api/contact/index.php`,
    //   headers: { 'content-type': 'application/json' },
    //   // data: this.state
    //   data
    // })
    //   .then(result => {
    //     if (result.data.sent) {
    //       this.setState({
    //         mailSent: true
    //       })
    //       this.setState({ error: false })
    //     } else {
    //       this.setState({ error: true })
    //     }
    //     this.resetInput()
    //   })
    //   .catch(error => {
    //     this.setState({ error: error.message })
    //     this.resetInput()
    //   })
    this.setState({ sendMsg: true });
  };

  handleNameInput = e => {
    let fname = this.state.fname;
    fname = e.currentTarget.value;
    this.setState({ fname });
  };
  handleEmailInput = e => {
    let email = this.state.email;
    email = e.currentTarget.value;
    this.setState({ email });
  };
  handleMessageInput = e => {
    let message = this.state.message;
    message = e.currentTarget.value;
    this.setState({ message });
  };

  resetInput = () => {
    this.setState(prev => {
      return { ...prev, fname: '', lname: '', message: '', email: '' };
    });
  };

  btnChange = op => {
    this.setState(prev => {
      return { ...prev, btn: op === 'disable' ? true : false };
    });
  };
  click = () => {
    this.setState({ sendMsg: false });
  };
  render() {
    return (
      <React.Fragment>
        {/* <HeaderContact></HeaderContact> */}
        <LayoutMain>
          <div className='contact'>
            <h1 className='contact__title '>Kontakt</h1>
            <section className='contact__section' id='form'>
              <div className='map-div'>
                <iframe
                  title='map'
                  src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2830.5747964515517!2d20.41722171553569!3d44.80985347909871!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a6ff312ba96e3%3A0x15c217af8d259944!2sTeretana%20Integral!5e0!3m2!1ssr!2srs!4v1583884154653!5m2!1ssr!2srs'
                  className='map-div__map '
                ></iframe>
                {/* <h3>My Google Maps Demo</h3>

               <GoogleMap></GoogleMap> */}
              </div>
              <div className='contact__form '>
                <form onSubmit={this.handleSubmit} className='form '>
                  <div className='u-margin-bottom-medium'>
                    <h2 className='header2'>Kontaktirajte nas</h2>
                  </div>
                  <br />

                  <div className='form__group'>
                    <label htmlFor='name' className='form__label'>
                      {/* Full name */}
                      Ime i prezime
                    </label>
                    <input
                      value={this.state.fname}
                      type='text'
                      className='form__input'
                      placeholder='Ime i prezime'
                      onChange={this.handleNameInput}
                      id='name'
                      required
                    />
                  </div>
                  <br></br>
                  <div className='form__group'>
                    <label htmlFor='email' className='form__label'>
                      {/* Email address */}
                      Email adresa
                    </label>
                    <input
                      value={this.state.email}
                      type='email'
                      className='form__input'
                      placeholder='e-mail'
                      id='email'
                      required
                      onChange={this.handleEmailInput}
                    />
                  </div>
                  <br />
                  <br />
                  <div className='form__group'>
                    <textarea
                      value={this.state.message}
                      placeholder='Vaša poruka '
                      className='form__textarea'
                      name='textarea'
                      id='textarea'
                      onChange={this.handleMessageInput}
                    ></textarea>
                  </div>
                  <br />

                  <div className='form__group'>
                    <button className='button btn--animated btn--pink-dark btn'>
                      Pošalji poruku
                    </button>
                    {this.state.sendMsg ? (
                      <Modal
                        styleModal='modal'
                        styleModalBody='modal__body '
                        click={() => this.click()}
                      >
                        <div className='modal__div'>
                          <h1>
                            Hvala što ste nas kontaktirali! <br /> Odgovorićemo
                            u što kraćem roku.
                            <br />
                            <span className='header-about__title1'>
                              🥊{' '}
                            </span>{' '}
                            Sportski pozdrav iz Integrala
                          </h1>
                          <button
                            // disable={this.state.btn}
                            onClick={() => {
                              this.resetInput();
                              this.setState({ sendMsg: false });
                            }}
                            className='button btn--animated btn--pink-dark btn'
                          >
                            ⇦ Vrati se na kontakt stranu
                          </button>
                        </div>
                      </Modal>
                    ) : null}
                  </div>
                </form>
              </div>
              <div className='contact__info'>
                <div className='contact__info-one'>
                  <br />
                  <h5 style={{ color: 'yellow', fontSize: '3rem' }}>
                    Info
                  </h5>{' '}
                  <br />
                  <span>
                    Bulevar Milutina Milankovića 74a <br />
                    <i className='fa fa-phone'></i>Phone: 011/311-32-25;
                    060/165-75-72{' '}
                  </span>
                  <br />
                  <span>
                    <i className='fa fa-envelope'></i>Email:
                    teretanaintegralnbg@gmail.com
                  </span>
                </div>
                <br /> <br />
                <div className='contact__info-one'>
                  <h5 style={{ color: 'yellow', fontSize: '3rem' }}>
                    Radno vreme
                  </h5>
                  <br />
                  <span>
                    <i className='fa fa-check'></i>Radni dani: 09:00 - 22:00{' '}
                  </span>
                  <br />
                  <span>
                    <i className='fa fa-check'></i>Subota: 09:00 - 20:00{' '}
                    <i className='fa fa-check'></i>Nedelja: 10:00 - 15:00{' '}
                  </span>
                </div>
              </div>
            </section>
          </div>
        </LayoutMain>
      </React.Fragment>
    );
  }
}

export default ContactPage;
//  handleSubmit = async e => {
//    // console.log(e.currentTarget.value);
//    e.preventDefault();

//    const fullName = this.state.fullName;
//    const email = this.state.email;
//    const userText = this.state.userText;

//    const data = `fullName=${fullName}&userText=${userText}&email=${email}`;

//    let xml = new XMLRequest(data);

//    this.btnChange("disable");
//    // 'http://localhost:1992/react-contact-form/api/contact/index.php

//    // '/react-contact-form/api/contact/index.php

//    xml
//      .sendRequest("post", "php/contact.php")
//      .then(res => {
//        this.resetInput();
//        // f from Modal class!
//        // modal.showModal();

//        this.btnChange("enable");
//      })
//      .catch(() => {
//        let errorHeader = "Error Occurred!!";
//        let errorMsg =
//          "There was a problem whit the server or your internet connection, please try again.";
//        // modal.addHeaderAndParagText(errorHeader, errorMsg);
//        // modal.showModal();

//        this.btnChange("enable");
//        this.resetInput();
//      });
//  };

import React, { Component } from "react";
import Navigation from "../../components/Navigation/Navigation";

import Footer from "../../components/Footer/Footer";


export class LayoutMain extends Component {
  render() {
    return (
      <React.Fragment>
        <Navigation />

        <div className="container">{this.props.children}</div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default LayoutMain;
import React, { Component } from 'react';

export class HeaderAboutUs extends Component {
  state = {};

  render() {
    return (
      <header className="header-about">
        <div className={`header-about__box `}>
          <div className="header-about__title3">
            {' '}
            <span className="header-about__title1">🥊 </span>
          </div>
          <span className="header-about__kick">Teretana Integral</span>
        </div>
      </header>
    );
  }
}

export default HeaderAboutUs;

import React, { Component } from 'react';

import { data } from '../K/photoService.js';
import { LayoutMain } from '../../containers/LayoutContainer/LayoutMain';
export class PhotosPage extends Component {
  state = {
    data: data,
    chosenId: 1
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  handleSlider = (chosenId) => {
    if (this.state.chosenId > 0 && this.state.chosenId < 17) {
      //   total = total + 1;
      let first = this.state.data.splice(1, 1);
      this.state.data.push(first[0]);
      chosenId++;
      this.setState({ chosenId, data });
    } else {
      let first = this.state.data.splice(1, 1);

      this.state.data.push(first[0]);
      chosenId = 1;
      this.setState({ chosenId, data });
    }
  };
  handleSlider1 = (chosenId) => {
    if (this.state.chosenId > 0 && this.state.chosenId < 18) {
      //   total = total + 1;
      var first = this.state.data.splice(1, 1);

      this.state.data.push(first[0]);
      chosenId--;
      this.setState({ chosenId, data });
    }
  };
  handleImg = (img) => {
    var first = this.state.data.splice(1, 1);
    this.state.data.push(first[0]);
    this.chosenId++;
    this.setState({ chosenId: img, data });
  };
  render() {
    return (
      <React.Fragment>
        <LayoutMain>
          <div className="photos-section">
            <button
              onClick={() => this.handleSlider1(this.state.chosenId)}
              className="photos-box__button photos-box__button--2 "
            >
              ⇦
            </button>
            <button
              onClick={() => this.handleSlider(this.state.chosenId)}
              className="photos-box__button photos-box__button--1"
            >
              ⇨
            </button>
            <h1 className="photos-section__header ">Galerija</h1>
            <div className="photos-box">
              {this.state.data.map((m) => (
                <img
                  key={m.id}
                  src={m.img}
                  onClick={() => this.handleImg(m.id)}
                  className={`photos-box__img photos-box__img--${m.id}
             `}
                  alt=""
                />
              ))}
              {this.state.chosenId ? (
                <div className="photos-box__main ">
                  {this.state.data.map((m) =>
                    m.id === this.state.chosenId ? (
                      <img
                        key={m.id}
                        src={m ? m.img : null}
                        className={` ${
                          this.state.chosenId === m.id
                            ? 'photos-box__main--img'
                            : ''
                        } `}
                        alt=""
                      />
                    ) : null
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </LayoutMain>
      </React.Fragment>
    );
  }
}

export default PhotosPage;

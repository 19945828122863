import img1 from '../../img1/gym1.jpg';
import img2 from '../../img1/gym2.jpg';
import img3 from '../../img1/gym3.jpg';
import img4 from '../../img1/gym4.jpg';
import img5 from '../../img1/gym5.jpg';
import img6 from '../../img1/gym6.jpg';
import img7 from '../../img1/gym7.jpg';
import img8 from '../../img1/gym8.jpg';
import img9 from '../../img1/gym9.jpg';
import img10 from '../../img1/gym10.jpg';
import img11 from '../../img1/gym11.jpg';
import img12 from '../../img1/gym12.jpg';
import img13 from '../../img1/gym13.jpg';
import img14 from '../../img1/gym14.jpg';
import img15 from '../../img1/gym15.jpg';
import img16 from '../../img1/gym16.jpg';
import img17 from '../../img1/gym17.jpg';

export const data = [
  {
    img: img1,
    id: 1
  },
  {
    img: img2,
    id: 2
  },
  {
    img: img3,
    id: 3
  },
  {
    img: img4,
    id: 4
  },
  {
    img: img5,
    id: 5
  },
  {
    img: img6,
    id: 6
  },

  {
    img: img7,
    id: 7
  },
  {
    img: img8,
    id: 8
  },
  {
    img: img9,
    id: 9
  },
  {
    img: img10,
    id: 10
  },
  {
    img: img11,
    id: 11
  },
  {
    img: img12,
    id: 12
  },
  {
    img: img13,
    id: 13
  },
  {
    img: img14,
    id: 14
  },
  {
    img: img15,
    id: 15
  },
  {
    img: img16,
    id: 16
  },
  {
    img: img17,
    id: 17
  }
];

import React from 'react';

function footer(props) {
  const days = [
    { day: 'Ponedeljak', time: '09:00 - 22:00' },
    { day: 'Utorak', time: '09:00 - 22:00' },
    { day: 'Sreda', time: '09:00 - 22:00' },
    { day: 'Četvrtak', time: '09:00 - 22:00' },
    { day: 'Petak', time: '09:00 - 22:00' },
    { day: 'Subota', time: '09:00  -  20:00' },
    { day: 'Nedelja', time: '10:00  -  15:00' }
  ];
  return (
    <React.Fragment>
      <footer className="footer-box">
        <div className="footer-box__social">
          <h3 className="header2 u-text-center u-color-pink">
            Nađite nas i na <div className="arrow">⇩</div>
          </h3>
          <div className="footer-box__contact-icons">
            {/* <a
            href="https://www.linkedin.com/company/skydreamweb"
            target="_blank"
            class="footer-box__icon"
          >
            <i class="fab fa-linkedin"></i>
          </a> */}
            <a
              href="https://www.facebook.com/teretanaintegralnbg"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-box__icon"
            >
              <i className="fab fa-facebook"></i>
            </a>
            <a
              href="https://www.instagram.com/teretanaintegral/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-box__icon"
            >
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>

        <div className="footer-box__time-info">
          <div className="schedule">
            <h3 className="header2 u-text-center u-color-pink">Radno vreme</h3>
            {days.map((m) => (
              <div key={m.day} className="schedule__item">
                <span className="schedule__item--1">{m.day}</span>
                <span className="schedule__item--2">{m.time}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="footer-box__contact-info">
          <h3 className="header2 u-color-pink">Kontakt</h3>

          <div className="u-color-white">
            Adresa: Bulevar Milutina Milankovića 74a <br />
            Telefon: 011/311-32-25; 060/165-75-72 <br />
            Email: teretanaintegralnbg@gmail.com
          </div>
        </div>
      </footer>
      <div
        style={{
          width: '100%',
          backgroundColor: '#4d4d4d',
          color: 'yellow',
          textAlign: 'center',
          fontSize: '2rem',
          borderTop: '.1rem solid grey',
          boxShadow: '0 0 8px #4d4d4d'
        }}
      >
        {' '}
        2020 © SkyDream Web
      </div>
    </React.Fragment>
  );
}

export default footer;

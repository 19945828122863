import React, { Component } from 'react';

export class Header extends Component {
  render() {
    return (
      <header className="header">
        <div className="header__box">
          <div className="cover">
            <div className="cover__header">
              <h1 className="header-title header-title--1">
                TERETANA & FITNES
              </h1>
              Prostor u kom se nalazi teretana odlikuje se prostranošću i
              velikim brojem sprava. Pored prostora u kom se nalazi teretana,
              raspolažemo i sa velikom salom za grupne programe, površine 120m²,
              kao i sa dve manje sale, opremljene rekvizitima za različite
              vidove aktivnosti.
            </div>

            <span className="cover__header1">
              <h1 className="header-title header-title--1">
                {' '}
                DOBRO DOŠLI U INTEGRAL{' '}
              </h1>
              Dođite da zajedno treniramo u odlično opremljenom prostoru za
              različite vidove aktivnosti i sportskom ambijentu koji negujemo
              dugi niz godina.
            </span>
          </div>
          <span className="header__box--text2">
            <span className="header2"></span>
          </span>

          <div className="header__section">
            <div className="header__home">
              <span className="gym">
                <span className="gym__icons gym__icons--2"></span>

                <span className="gym__icons gym__icons--3"></span>
                <span className="gym__icons gym__icons--1"></span>
              </span>
              <span className="gym u-margin-top-small">
                <span className="gym__icons gym__icons--4"></span>

                <span className="gym__icons gym__icons--5"></span>
                <span className="gym__icons gym__icons--6"></span>
                <span className="gym__icons gym__icons--7"></span>
              </span>
            </div>
            <h1 className="header__main-title">Teretana Integral </h1>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;

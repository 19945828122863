import React from "react";

import ReactDOM from "react-dom";


const Modal = (props) => {
  return ReactDOM.createPortal(
    <div className={props.styleModal} onClick={props.click}>
      <div
        className={props.styleModalBody}
        onClick={(e) => e.stopPropagation()}
      >
        {props.children}
      </div>
    </div>,
    document.querySelector("#modal")
  );
};

export default Modal;

import React, { Component } from 'react';
import logo from '../../img/logo.png';
import { NavLink } from 'react-router-dom';

export class Navigation extends Component {
  state = {
    navShow: true,
    btnOpen: true,
    btnClose: false,
    navItems: [
      { name: 'Naslovna', id: 1, link: '/' },
      {
        name: 'O nama',
        id: 2,
        link: '/teretana/integral'
      },
      {
        name: 'Programi',
        id: 3,
        link: '/programi'
      },
      {
        name: 'Cenovnik',
        id: 4,
        link: '/cenovnik'
      },
      {
        name: 'Galerija',
        id: 5,
        link: '/galerija'
      },
      {
        name: 'Kontakt',
        id: 6,
        link: '/kontakt'
      }
    ]
  };
  handleCloseNav = () => {
    this.setState({ btnClose: false, btnOpen: true, navShow: false });
  };
  handleOpenNav = () => {
    this.setState({ btnClose: true, btnOpen: false, navShow: true });
  };
  render() {
    return (
      <div
        className={`${
          this.state.btnOpen ? 'navigation navigation__box' : 'navigation'
        }`}
      >
        <nav className="nav-box">
          <div className="logo">
            <img src={logo} alt="Logo" />
          </div>
          {this.state.btnClose && !this.state.btnOpen ? (
            <div
              className="navigation__closeBtn"
              onClick={() => this.handleCloseNav()}
            >
              X
            </div>
          ) : null}
          {this.state.btnOpen && !this.state.btnClose ? (
            <div
              className="navigation__openBtn"
              onClick={() => this.handleOpenNav()}
            >
              <span className="navigation__openBtn--1"></span>
              <span className="navigation__openBtn--2"></span>
              <span className="navigation__openBtn--3"></span>
            </div>
          ) : null}
          <ul className="nav-items">
            {this.state.navShow
              ? this.state.navItems.map((items) => (
                  // <li className="nav-items__item" key={items.id}>
                  <NavLink
                    key={items.name}
                    activeStyle={
                      this.state.btnOpen
                        ? {
                            color: '#4d4d4d',

                            animation: 'paintBackground1  1s forwards'
                          }
                        : {
                            // background: "yellow",
                            color: '#e91e63',
                            // color: "yellow",
                            fontSize: '2.5rem'
                          }
                    }
                    className={`nav-items__item nav-items__item--${items.id}`}
                    exact
                    to={items.link}
                  >
                    {items.name}
                  </NavLink>
                  // </li>
                ))
              : null}
          </ul>
        </nav>
      </div>
    );
  }
}

export default Navigation;
